import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableDomain extends SearchTable {
    params = {
        sort_field: 'name',
        sort_order: 'asc',
        page: 1
    };
    formId = 'domain-search-form';
    varPrefix = 'searchTableDomain';
    loadParamsRegExp = [
        /\/domain\/(.*)/g
    ];

    apiUrl = '/api/domains';
    sortDef = [
        { key: 'name', name: 'Name'},
        { key: 'created', name: 'Erstellt' }
    ];

    colDef = [
        { sortField: 'name', text: 'Name' },
        { text: 'Status'},
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr>
                {this.renderTableCellName(row)}
                {this.renderTableCellStatus(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellName(row) {
        return(
            <td>
                {row.name}
            </td>
        )
    }

    renderTableCellStatus(row) {
        return(
            <td>
                {row.user_count} {' '}
                {this.renderStatusIcon('users', true)},{' '}
                {row.user_domain_role_count} {' '}
                {this.renderStatusIcon('pencil-square-o', true)}
            </td>
        )
    }
    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/domain/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/domain/${row.id}/edit`, 'edit', 'bearbeiten', this.capabilities.includes('edit'))}
                {this.renderTableCellActionIcon(`/domain/${row.id}/delete`, 'trash', 'löschen', this.capabilities.includes('delete'))}
            </td>
        )
    }
}