import '../sass/base.scss'
import '../sass/webapp.scss'

import React from "react";
import ReactDOM from "react-dom";

import Common from './Common';

import SearchTableDomain from './SearchTable/SearchTableDomain';
import SearchTableUser from './SearchTable/SearchTableUser';
import SearchTableAlias from './SearchTable/SearchTableAlias';


$(document).ready(function () {
    window.common = new Common();

    /*
     search tables
     */
    if ($('#domain-search-results').length) {
        ReactDOM.render(
            <SearchTableDomain ref={(searchTableDomain) => {window.searchTableDomain = searchTableDomain}} />,
            document.getElementById("domain-search-results")
        );
    }
    if ($('#user-search-results').length) {
        ReactDOM.render(
            <SearchTableUser ref={(searchTableUser) => {window.searchTableUser = searchTableUser}} />,
            document.getElementById("user-search-results")
        );
    }
    if ($('#alias-search-results').length) {
        ReactDOM.render(
            <SearchTableAlias ref={(searchTableAlias) => {window.searchTableAlias = searchTableAlias}} />,
            document.getElementById("alias-search-results")
        );
    }
});

