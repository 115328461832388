import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableUser extends SearchTable {
    params = {
        sort_field: 'email',
        sort_order: 'asc',
        page: 1
    };
    formId = 'user-search-form';
    varPrefix = 'searchTableUser';
    loadParamsRegExp = [
        /\/user\/(.*)/g
    ];

    apiUrl = '/api/users';
    sortDef = [
        { key: 'email', name: 'E-Mail'},
        { key: 'created', name: 'Erstellt' }
    ];

    colDef = [
        { sortField: 'email', text: 'E-Mail' },
        { text: 'Status'},
        { text: 'Aktionen' }
    ];

    init() {
        let form = $('#' + this.formId);
        if (form.data('domain')) {
            this.params.domain = form.data('domain');
        }
        super.init();
    }

    renderTableRow(row) {
        return (
            <tr>
                {this.renderTableCellEmail(row)}
                {this.renderTableCellStatus(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellStatus(row) {
        return(
            <td>
            </td>
        )
    }

    renderTableCellEmail(row) {
        return(
            <td>
                {row.email}<br/>
                {row.first_name && row.last_name &&
                    <small>{row.first_name} {row.last_name}</small>
                }
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/user/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/user/${row.id}/edit`, 'edit', 'bearbeiten', this.capabilities.includes('edit'))}
                {this.renderTableCellActionIcon(`/user/${row.id}/password`, 'key', 'Passwort ändern', this.capabilities.includes('edit'))}
                {this.renderTableCellActionIcon(`/user/${row.id}/delete`, 'trash', 'löschen', this.capabilities.includes('delete'))}
            </td>
        )
    }
}